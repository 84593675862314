import { ally } from '@/api'
import store from '@/store'

const state = () => ({
  legal_guardian: {
    name: '',
    email: '',
    birthdate: '',
    cpf: '',
    hasLegalGuardian: false,
    zipcode: '',
    address: '',
    number: '',
    complement: '',
    city: '',
    state: '',
    country_code: '',
    phone: '',
  },
  responsible: {
    name: '',
    email: '',
    birthdate: '',
    cpf: '',
    student_id: '',
    hasResponsible: false,
  },
})

const getters = {
  legal_guardian: (state) => state.legal_guardian,
  legalGuardian(state) {
    const legalGuardianData = JSON.parse(
      localStorage.getItem('legalGuardianData')
    )
    return state.legal_guardian.name ? state.legal_guardian : legalGuardianData
  },
}

const actions = {
  async getLegalGuardianData({ commit }, payload) {
    await ally
      .get(`/student?paginate=0&where={"email":"${payload}"}`, {
        params: {
          with: JSON.stringify(['responsible']),
        },
      })
      .then(({ data }) => {
        commit('setResponsible', JSON.stringify(data[0]))
      })
      .catch()
  },
  async updateLegalGuardianData({ commit }, payload) {
    const request = {
      responsible_name: payload.name,
      responsible_email: payload.email,
      responsible_birthdate: payload.birthdate,
      responsible_cpf: payload.cpf,
    }
    ally
      .put(`/student/${payload.student_id}`, request)
      .then((res) => {
        commit('setLegalGuardian', payload)
      })
      .catch((err) => {
        store.commit('snackbar/showSnackbar', {
          text: 'Ocorreu um erro na alteração',
          color: 'error',
        })
      })
      .finally(() => {
        store.commit('snackbar/showSnackbar', {
          text: 'Os dados foram alterados com sucesso',
          color: 'success',
        })
      })
  },
}

const mutations = {
  setLegalGuardian(state, value) {
    state.legal_guardian.name = value.name
    state.legal_guardian.email = value.email
    state.legal_guardian.birthdate = value.birthdate
    state.legal_guardian.cpf = value.cpf
    state.legal_guardian.hasLegalGuardian = value.hasLegalGuardian
    state.legal_guardian.zipcode = value.zipcode
    state.legal_guardian.address = value.address
    state.legal_guardian.number = value.number
    state.legal_guardian.complement = value.complement
    state.legal_guardian.city = value.city
    state.legal_guardian.state = value.state
    state.legal_guardian.country_code = value.country_code
    state.legal_guardian.areaCode = '16'
    state.legal_guardian.phone = value.phone
    localStorage.setItem('legalGuardianData', JSON.stringify(value))
  },
  setLegalGuardianData(state, value) {
    state.legal_guardian.name = value.name
    state.legal_guardian.email = value.email
    state.legal_guardian.birthdate = value.birthdate
    state.legal_guardian.cpf = value.cpf
    state.legal_guardian.areaCode = '16'
    state.legal_guardian.phone = '99999999'
    localStorage.setItem('legalGuardianData', JSON.stringify(value))
  },
  setResponsible(state, value) {
    if (JSON.parse(value).responsible) {
      state.responsible = JSON.parse(value).responsible
      state.responsible.hasResponsible = true
      localStorage.setItem(
        'legalGuardianInfo',
        JSON.stringify(state.responsible)
      )
    }
  },
  resetResponsible(state) {
    state.responsible = ''
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
