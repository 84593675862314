import Vue from 'vue'
import Vuex from 'vuex'

import student from './modules/checkout/student'
import legal_guardian from './modules/checkout/legal-guardian'
import snackbar from './modules/checkout/snackbar'
import auth from './modules/auth'
import quote from './modules/quote'
import travel from './modules/travel'
import checklist from './modules/checklist'
import payment from './modules/payment'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    student,
    legal_guardian,
    snackbar,
    auth,
    quote,
    travel,
    checklist,
    payment,
  },
  strict: debug,
})
