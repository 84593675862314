const header = () => import('@/components/shared/Header.vue')
const footer = () => import('@/components/shared/Footer.vue')
const navbar = () => import('@/components/dashboard/Navbar.vue')
const bottom = () => import('@/components/shared/Bottom.vue')

const routes = [
  {
    path: '/checkout/:quote_id/:hash',
    name: 'checkout',
    components: {
      header,
      bottom,
      default: () => import('@/views/Checkout.vue'),
    },
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/espaco-estudante',
    name: 'Espaço do Estudante',
    components: {
      navbar,
      bottom,
      default: () => import('@/views/StudentArea.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/meu-perfil',
    name: 'Meu Perfil',
    props: true,
    components: {
      navbar,
      bottom,
      default: () => import('@/views/MyProfile.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/informacao-pessoal',
    name: 'Informações Pessoais',
    props: true,
    components: {
      navbar,
      bottom,
      default: () => import('@/views/MyInfo.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/meus-intercambios',
    name: 'Meus Intercâmbios',
    props: true,
    components: {
      navbar,
      bottom,
      default: () => import('@/views/MyTravel.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/pagamento-intercambio',
    name: 'Pagamento de Intercâmbio',
    props: true,
    components: {
      navbar,
      bottom,
      default: () => import('@/views/PaymentDetail.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/plano-pagamento',
    name: 'Plano de Pagamento',
    props: true,
    components: {
      navbar,
      bottom,
      default: () => import('@/components/payment/PaymentDetailPlan.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dados-responsavel',
    name: 'Dados do Responsável',
    props: true,
    components: {
      navbar,
      bottom,
      default: () => import('@/views/LegalGuardianProfile.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/seguranca-conta',
    name: 'Segurança da Conta',
    props: true,
    components: {
      navbar,
      bottom,
      default: () => import('@/views/Security.vue'),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    components: {
      default: () => import('@/views/Login.vue'),
    },
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/404',
    components: {
      navbar,
      default: () => import('@/components/shared/404.vue'),
    },
  },
  {
    path: '/:hash',
    name: 'Área do estudante',
    components: {
      bottom,
      default: () => import('@/views/LoginArea.vue'),
    },
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '*',
    components: {
      navbar,
      default: () => import('@/components/shared/404.vue'),
    },
  },
  {
    path: '/',
    components: {
      default: () => import('@/views/Login.vue'),
    },
  },
]
export default routes
