import { ally, quote } from '@/api'
import store from '@/store'

const state = () => ({
  walletList: [],
  selectedWallet: [],
  walletDetail: [],
  paymentDetail: [],
  travelPaymentInfo: [],
  travelToPay: [],
  convertedValue: 0,
  dailyCotation: [],
  paymentSuccess: false,
  pixPayment: null,
  pixStatus: null,
  paymentData: null,
  pixMethod: null,
  paymentPlan: null,
})

const getters = {
  paymentPlan(state) {
    return state.paymentPlan ? state.paymentPlan : []
  },
  lastPixStatus(state) {
    return state.pixStatus ? state.pixStatus : localStorage.getItem('pixStatus')
  },
  lastPixPayment(state) {
    return state.pixPayment
      ? state.pixPayment
      : JSON.parse(localStorage.getItem('pixPayment'))
  },
  travelToPay(state) {
    return state.travelToPay.lenght > 0
      ? state.travelToPay
      : JSON.parse(localStorage.getItem('travelToPay'))
  },
  selectedTravelToPay(state) {
    return state.travelToPay.length > 0
      ? state.travelToPay
      : JSON.parse(localStorage.getItem('travelToPay'))
  },
  allPaid(state, getters) {
    const travel = getters.selectedTravelToPay
    return (
      travel.paymentInfo.total_amount - travel.paymentInfo.amount_paid === 0
    )
  },
  daysToPay(state, getters) {
    const allPaid = getters.allPaid
    const daysLeft = getters.selectedTravelToPay.paymentInfo.daysToPay
    if (daysLeft <= 0 && allPaid) {
      return 'Intercâmbio em curso'
    } else if (daysLeft < 0 && !allPaid) {
      return 'Atrasado em ' + -1 * daysLeft + ' dias'
    } else if (daysLeft === 0 && !allPaid) {
      return 'Hoje'
    } else {
      return daysLeft + ' dias'
    }
  },
}

const actions = {
  async getDailyCotation({ commit }, currency) {
    await quote.get(`/currencyplugandplay`).then(({ data }) => {
      const response = data.filter((value) => value.currency.code === currency)
      commit('setDailyCotation', response)
      localStorage.setItem('dailyCotation', JSON.stringify(response))
    })
  },
  async getPaymentPlan({ commit }, quote_id) {
    const url = '/quotehash/' + quote_id + '?hash=hash'
    await quote.get(url).then(({ data }) => {
      const response = data.paymentplan
      commit('setPaymentPlan', response)
    })
  },
  async getWalletList({ commit }, id) {
    ally.get(`/studentwallet?student_id=${id}`).then(({ data }) => {
      const response = data
      commit('setWalletList', response)
    })
  },
  async getWalletDetail({ commit }, id) {
    ally.get(`studentwallet/${id}`).then(({ data }) => {
      const response = data
      commit('setWalletDetail', response)
    })
  },
  async getPaymentDetail({ commit }, id) {
    await ally
      .get(`/travelpaymentstatement?travel_id=${id}`)
      .then(({ data }) => {
        const response = data
        commit('setPaymentDetail', response)
        localStorage.setItem('paymentDetail', JSON.stringify(response))
      })
  },
  async getTravelPaymentInfo({ commit }, id) {
    commit('resetTravelPaymentInfo')
    ally.get(`/travel?student_id=${id}`).then(({ data }) => {
      const response = data
      response.forEach(function (travel) {
        ally.get(`/travel/${travel.id}`).then(({ data }) => {
          commit('setTravelPaymentInfo', data)
        })
      })
    })
  },
  async getConvertedValue({ commit }, value) {
    await quote
      .get('convert', {
        params: value,
      })
      .then(({ data }) => {
        const response = data
        commit('setConvertedValue', response.value)
      })
  },
  async createPixPayment({ commit }, value) {
    await ally
      .post('createpix', {
        value: value.valueToPay,
        studentAS_id: value.id,
      })
      .then(({ data }) => {
        const response = data
        commit('setPixPayment', response)
        commit('setPixMethod', value.method)
      })
  },
  async getPixStatus({ getters, commit }) {
    if (getters.lastPixPayment) {
      await ally
        .get('pixstatus', {
          params: { id: getters.lastPixPayment.id },
        })
        .then(({ data }) => {
          const response = data
          console.log('getPixStatus')
          if (response.status === 'pending') {
          }
          if (response.status === 'canceled') {
          }
          if (response.status === 'succeeded') {
            // console.log('pagou')
          }
          commit('setPixStatus', response.status)
        })
    }
  },
  async sendPaymentLater({ commit, getter }, value) {
    const student = store.getters['student/studentInfo']
    const legalGuardian = store.getters['legal_guardian/legalGuardian']
    const quoteInfo = store.getters['quote/quoteInfo']
    const quoteStudent = store.getters['quote/quoteStudent']
    const office = store.getters['quote/officeInfo']
    const course = store.getters['quote/coursesInfo'][0]
    const studentData = {
      name: student.name,
      email: student.email,
      cpf: student.cpf,
    }
    const dataCheckout = {
      user: student.hasResponsible ? legalGuardian : studentData,
      quote_id: course.quote_id,
      value: 0,
      destiny_currency_amount_paid: 0,
      student_id: quoteStudent.student_id,
      studentAS_id: student.id,
      office_id: office.office_id,
      account_id: quoteInfo.account_id,
      account_name: office.agency_name,
      city_id: course.course.campus.city_id ?? null,
      country_code: course.course.campus.country_code ?? null,
      destiny_currency_code: quoteInfo.converted_currency_code,
      destiny_currency_total_amount: quoteInfo.converted_value,
      destiny_currency_value: quoteInfo.currency.value,
      payLater: true,
      loginType: store.getters['auth/loginType'],
      creator_name: quoteInfo.quote_creator_name,
      creator_email: quoteInfo.quote_creator_email,
      pravalerInstalment: quoteInfo.pravalerInstalment,
      startDate: course.startDate,
    }
    try {
      const data = await ally.post('/installmentcredit', dataCheckout)
      commit('setPaymentData', data)
    } catch (error) {
      console.log(error)
    }
  },
  async sendPaymentWithDeposit(
    { commit },
    { requestValueToPay, file, methodPayment, travelId, instalmentId }
  ) {
    const student = store.getters['student/studentInfo']
    const legalGuardian = store.getters['legal_guardian/legalGuardian']
    const quoteInfo = store.getters['quote/quoteInfo']
    const quoteStudent = store.getters['quote/quoteStudent']
    const office = store.getters['quote/officeInfo']
    const course = store.getters['quote/coursesInfo'][0]
    const studentData = {
      name: student.name,
      email: student.email,
      cpf: student.cpf,
    }
    const destinyCurrencyTotalAmount = quoteInfo.totalValues.filter(
      (value) => value.currency_symbol !== 'R$' && value.value !== 0
    )[0].value

    let destiny_currency_value = quoteInfo.currency.filter(
      (value) => value.currency.code === quoteInfo.converted_currency_code
    )
    console.log(destiny_currency_value)
    const dataCheckout = {
      travel_id: travelId,
      instalment_id: instalmentId,
      user: student.hasResponsible ? legalGuardian : studentData,
      quote_id: course.quote_id,
      value: 0,
      destiny_currency_amount_paid: requestValueToPay,
      student_id: quoteStudent.student_id,
      studentAS_id: student.id,
      office_id: office.office_id,
      account_id: quoteInfo.account_id,
      account_name: office.agency_name,
      city_id: course.course.campus.city_id ?? null,
      country_code: course.course.campus.country_code ?? null,
      destiny_currency_code: quoteInfo.converted_currency_code,
      destiny_currency_total_amount: quoteInfo.converted_value,
      destiny_currency_value: destiny_currency_value.length
        ? destiny_currency_value[0].value
        : 1,
      paymentTypeFlex: methodPayment === 'flex',
      paymentWithDeposit: true,
      loginType: store.getters['auth/loginType'],
      creator_name: quoteInfo.quote_creator_name,
      creator_email: quoteInfo.quote_creator_email,
      startDate: course.startDate,
    }

    const formData = new FormData()

    formData.append('file', file[0])

    try {
      const { data } = await ally.post('/installmentcredit', dataCheckout)
      const {
        Travel: { paymentStatement_id },
      } = data

      formData.append('paymentStatement_id', paymentStatement_id)

      await ally.post('/uploadpaymentvoucher', formData)

      commit('setPaymentData', data)
    } catch (error) {
      console.log(error)
    }
  },

  async sendPixPayment({ commit, getters }, value) {
    console.log('sendPixPayment')
    const student = store.getters['student/studentInfo']
    const legalGuardian = store.getters['legal_guardian/legalGuardian']
    const quoteInfo = store.getters['quote/quoteInfo']
    const quoteStudent = store.getters['quote/quoteStudent']
    const office = store.getters['quote/officeInfo']
    let course = store.getters['quote/coursesInfo']
    if (value.paymentType !== 'addPayment') {
      course = store.getters['quote/coursesInfo'][0]
    }
    const travel = getters.travelToPay
    const studentData = {
      name: student.name,
      email: student.email,
      cpf: student.cpf,
    }

    let destiny_currency_amount_paid =
      value.paymentType === 'addPayment'
        ? value.destinyAmountPaid
        : quoteInfo.totalValues[0].value
    let destiny_currency_total_amount =
      value.paymentType === 'addPayment'
        ? travel.paymentInfo.total_amount
        : value.destinyTotalAmount
    if (value.paymentType === 'flex') {
      destiny_currency_amount_paid = quoteInfo.totalValues[0].value * 0.3
      destiny_currency_total_amount = quoteInfo.totalValues[0].value
    } else if (value.paymentType !== 'addPayment') {
      destiny_currency_amount_paid = quoteInfo.totalValues[0].value
      destiny_currency_total_amount = quoteInfo.totalValues[0].value
    }
    const dataCheckout = {
      user: student.hasResponsible ? legalGuardian : studentData,
      card: {
        installmentQuantity: 1,
      },
      address: {
        street: student.address,
        number: student.number,
        complement: '',
        district: student.neighborhood,
        postalCode: student.zipcode,
        city: student.city,
        state: student.state,
        country: student.country_code,
      },
      // destiny_currency_code
      // destiny_currency_value
      quote_id:
        value.paymentType === 'addPayment' ? travel.quote_id : quoteInfo.id,
      description: 'Pagamento Ally Students',
      itemQuantity: '01',
      value: value.valueToPay,
      paymentWithPix: true,
      quoteHash: value.paymentType === 'addPayment' ? '' : quoteInfo.hash,
      reference: value.paymentType === 'addPayment' ? '' : quoteInfo.hash,
      code: value.code,
      status: value.status,
      account_id:
        value.paymentType === 'addPayment'
          ? travel.account_id
          : quoteInfo.account_id,
      account_name:
        value.paymentType === 'addPayment' ? '' : office.agency_name,
      city_id:
        value.paymentType === 'addPayment'
          ? travel.city.city_id
          : course.course.campus.city_id,
      loginType: store.getters['auth/loginType'],
      startDate:
        value.paymentType === 'addPayment'
          ? travel.startDate
          : course.startDate,
      endDate:
        value.paymentType === 'addPayment' ? travel.endDate : course.endDate,
      country_code:
        value.paymentType === 'addPayment'
          ? travel.country.code
          : course.course.campus.country_code,
      office_id:
        value.paymentType === 'addPayment'
          ? travel.office_id
          : office.office_id,
      student_id:
        value.paymentType === 'addPayment'
          ? travel.studentPP_id
          : quoteStudent.student_id,
      studentAS_id: student.id,
      travel_id: value.travel_id,
      paymentTypeFlex: value.paymentType === 'flex' ? true : false,
      creator_name:
        value.paymentType === 'addPayment' ? '' : quoteInfo.quote_creator_name,
      creator_email:
        value.paymentType === 'addPayment' ? '' : quoteInfo.quote_creator_email,
      destiny_currency_amount_paid: destiny_currency_amount_paid,
      destiny_currency_code:
        value.paymentType === 'addPayment'
          ? travel.currency_code
          : quoteInfo.currency[0].destiny_code,
      destiny_currency_total_amount: destiny_currency_total_amount,
      destiny_currency_value:
        value.paymentType === 'addPayment'
          ? localStorage.getItem('destinyCurrencyValue')
          : quoteInfo.currency[0].value,
    }
    try {
      const data = await ally.post('/installmentcredit', dataCheckout)
      commit('setPaymentData', data)
    } catch (error) {
      console.log(error)
    }
  },

  async getBancaryInfo({ commit }) {
    const currency = JSON.parse(
      localStorage.getItem('selectedTravel')
    ).currency_code
    try {
      const response = await fetch(
        `${process.env.VUE_APP_QUOTE_API}renewal-bank-accounts`,
        {
          Headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
        }
      )
      const data = await response.json()

      commit('setBancaryInfo', data[currency])
    } catch (e) {
      console.log(e)
    }
  },
}

const mutations = {
  setPaymentSuccess(state) {
    state.paymentSuccess = true
  },
  setPaymentData(state, value) {
    state.paymentData = value
    localStorage.setItem('paymentData', JSON.stringify(value))
  },
  resetPaymentSuccess(state) {
    state.paymentSuccess = false
  },
  setTravelToPay(state, value) {
    state.travelToPay = value
    state.pixPayment = null
    localStorage.setItem('pixPayment', null)
    localStorage.setItem('travelToPay', JSON.stringify(value))
  },
  resetPixPayment(state) {
    state.pixPayment = null
    localStorage.setItem('pixPayment', null)
  },
  setDailyCotation(state, value) {
    state.dailyCotation = value
  },
  setTravelPaymentInfo(state, value) {
    state.travelPaymentInfo.push(value)
  },
  setConvertedValue(state, value) {
    state.convertedValue = value
    localStorage.setItem('convertedValue', value)
  },
  resetTravelPaymentInfo(state) {
    state.travelPaymentInfo = []
  },
  resetConvertedValue(state) {
    state.convertedValue = 0
  },
  setWalletList(state, value) {
    state.walletList = value
    localStorage.setItem('walletList', JSON.stringify(value))
  },
  setSelectedWalletList(state, value) {
    state.selectedWallet = value
    localStorage.setItem('selectedWallet', JSON.stringify(value))
  },
  setWalletDetail(state, value) {
    state.walletDetail = value
    localStorage.setItem('selectedWalletDetail', JSON.stringify(value))
  },
  setPaymentDetail(state, value) {
    state.paymentDetail = value
    localStorage.setItem('selectedPaymentDetail', JSON.stringify(value))
  },
  setPixPayment(state, value) {
    state.pixPayment = value
    localStorage.setItem('pixPayment', JSON.stringify(value))
  },
  setPixStatus(state, value) {
    state.pixStatus = value
    localStorage.setItem('pixStatus', value)
  },
  setPixMethod(state, value) {
    state.pixMethod = value
  },
  setBancaryInfo(state, value) {
    state.bancaryInfo = value
  },
  setPaymentPlan(state, value) {
    state.paymentPlan = value
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
