import jwtDecode from 'jwt-decode'
import router from '@/router'
import store from '@/store'
import { quote } from '@/api'

const getDefaultState = () => {
  return {
    isAuthenticated: false,
    user: {},
  }
}

const state = () => ({
  isAuthenticated: false,
  user: {},
  account: null,
})

const getters = {
  snackbar: (state) => state.snackbar,
  isAuthenticated: (state) => state.isAuthenticated,
  user: (state) => {
    return localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : state.user
  },
  externalProviderAccess: () => {
    return localStorage.getItem('loginType') !== 'password'
  },
  loginType: () => {
    if (
      typeof localStorage.getItem('loginType') !== 'undefined' &&
      localStorage.getItem('loginType')
    ) {
      switch (localStorage.getItem('loginType')) {
        case 'password':
          return 'Email'
        case 'google':
          return 'Google'
        case 'facebook':
          return 'Facebook'
      }
    }
    return 'Email'
  },
  account: (state) => state.account,
}
const actions = {
  logout({ commit }) {
    return new Promise((resolve) => {
      commit('logout')
      resolve()
    })
  },
  async login({ commit }, value) {
    return new Promise((resolve) => {
      const user = jwtDecode(value.accessToken)
      localStorage.setItem('token', value.accessToken)
      localStorage.setItem('refreshToken', value.refreshToken)
      localStorage.setItem('user', JSON.stringify(user))
      commit('login', value)
      resolve()
    })
  },
  async getAccountInfo({ commit }, hash) {
    if (!hash) return

    const url = `/studentareaurl/${hash}`

    try {
      const { data } = await quote.get(url, {
        hash,
      })

      const accountLogoUrl = `https://api.sellead.com/uploads/account_${data.id}/account_logo.jpg`
      const account = { ...data, logo: accountLogoUrl }

      commit('setAccount', account)
    } catch (err) {
      console.error(err)
    }
  },
}

const mutations = {
  login(state, value) {
    state.user = jwtDecode(value.accessToken)
    state.isAuthenticated = true
    store.commit('payment/resetPixPayment')
    router.push('/espaco-estudante')
  },
  logout(state) {
    state.isAuthenticated = false
    store.commit('legal_guardian/resetResponsible')
    store.commit('payment/resetPixPayment')
    localStorage.clear()
    router.push('/login')
  },
  user(state, value) {
    const user = jwtDecode(value.accessToken)
    localStorage.setItem('user', JSON.stringify({ ...user }))
    state.user = user
  },
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setAccount(state, value) {
    state.account = value
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
