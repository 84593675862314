<template>
  <div style="margin-top: 30px" class="bottom text-center">
    <p class="bottom-text">
      <a href="https://allyhub.co/" target="_blank">Suporte</a> ·
      <a href="https://allyhub.co/" target="_blank">Políticas de Privacidade</a>
      · <a href="https://allyhub.co/" target="_blank">Termos</a> ·
      <a href="https://allyhub.co/" target="_blank">Informações da Empresa</a>
    </p>
    <p>Powered by <b>Ally</b>. All rights reserved.</p>
  </div>
</template>

<script>
export default {
  name: 'BottomComponent',
}
</script>

<style scoped>
.bottom {
  width: 100%;
  color: #0b8bbd !important;
  font-size: 13px;
}
.bottom-text {
  font-weight: bold;
}
</style>
