<template>
  <div>
    <v-breadcrumbs :items="items">
      <template #item="props">
        <router-link
          :to="{ name: props.item.name }"
          class="subtitle white--text no-link"
        >
          {{ props.item.alias }}
        </router-link>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script>
export default {
  name: 'BreadcrumbComponent',
  data() {
    return {
      items: [],
    }
  },
  watch: {
    $route() {
      this.items = this.$router.currentRoute.meta.breadcrumb
    },
  },
  beforeMount() {
    this.items = this.$router.currentRoute.meta.breadcrumb
  },
}
</script>

<style lang="scss" scoped>
/* stylelint-disable declaration-no-important */

/* TODO Arrumar os importants */

.no-link {
  font-size: 1rem !important;
  text-decoration: none;
}

.no-link:hover {
  color: #ffc107;
}
</style>
