import { ally } from '@/api'

const state = () => ({
  travelList: [],
  travelInfo: [],
  selectedTravel: [],
})

const getters = {}

const actions = {
  async getTravelList({ commit }, id) {
    await ally.get(`/travel?student_id=${id}`).then(({ data }) => {
      const response = data
      commit('setTravelList', response)
    })
  },
  async getTravelInfoById({ commit }, id) {
    await ally.get(`/travel/${id}`).then(({ data }) => {
      const response = data
      commit('setTravelInfo', response)
    })
  },
}

const mutations = {
  setTravelList(state, value) {
    state.travelList = value
    localStorage.setItem('travelList', JSON.stringify(value))
  },
  setTravelInfo(state, value) {
    state.travelInfo = value
    localStorage.setItem('travelDetailedInfo', JSON.stringify(value))
    localStorage.setItem('selectedTravel', JSON.stringify(value))
  },
  setSelectedTravel(state, value) {
    state.selectedTravel = value
    localStorage.setItem('selectedTravel', JSON.stringify(value))
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
