import axios from 'axios'

const API_URL = process.env.VUE_APP_STUDENT_API
const TOKEN = process.env.VUE_APP_PAGSEGURO_TOKEN

const publicKey = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  baseURL: API_URL,
})

export default publicKey
