<template>
  <div>
    <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

    <v-navigation-drawer v-model="drawer" absolute right temporary>
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="list-group-mobile">
          <v-list-item v-for="(item, index) in items" :key="index">
            <v-list-item-title class="pointer" @click="goTo(item.route)">{{
              item.title
            }}</v-list-item-title>
          </v-list-item>
          <v-divider color="#0EA2B3"></v-divider>
          <v-list-item>
            <v-list-item-title class="pointer" @click="$emit('logout')"
              >Logout</v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data: () => ({
    drawer: false,
    group: null,
    items:
      JSON.parse(localStorage.getItem('travelList')).length &&
      localStorage.getItem('travelList')
        ? [
            { title: 'Meu Perfil', route: '/meu-perfil' },
            { title: 'Meus Intercâmbios', route: '/meus-intercambios' },
          ]
        : [],
    studentInfo: JSON.parse(localStorage.getItem('studentInfo')),
  }),
  watch: {
    group() {
      this.drawer = false
    },
  },
  methods: {
    goTo(route) {
      this.$router.push(route)
    },
  },
}
</script>

<style type="scss" scoped>
.list-group-mobile {
  color: #0ea2b3;
  caret-color: #0ea2b3;
}
</style>
