<template>
  <div v-if="isAuthenticated" class="nav-logged">
    <v-img
      :src="require('../../assets/Allyhub/logo-header.png')"
      class="logo-header zoom"
      contain
      @click="$router.push('/espaco-estudante')"
    />
    <div class="creator-area">
      <UserDropdown @logout="disconnect()" />
    </div>
    <div class="creator-area-mobile">
      <UserNavigationDrawer @logout="disconnect()" />
    </div>
  </div>

  <div v-else-if="account" class="logo-mobile">
    <div
      id="main-logo-image"
      :style="{ backgroundImage: `url(${account.logo})`, marginTop: '20px' }"
    />
  </div>

  <div v-else class="nav">
    <v-img
      :src="require('../../assets/Allyhub/logo-header.png')"
      class="logo-header"
      contain
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserDropdown from '@/components/dashboard/UserDropdown.vue'
import UserNavigationDrawer from '@/components/dashboard/UserNavigationDrawer.vue'
export default {
  name: 'DashboardNavbarComponent',
  components: {
    UserDropdown,
    UserNavigationDrawer,
  },
  data() {
    return {
      drawer: false,
      items: ['web', 'shopping', 'videos', 'images', 'news'],
      isAuthenticated: localStorage.getItem('token'),
      tab: null,
    }
  },
  computed: {
    ...mapGetters('auth', ['account']),
  },
  methods: {
    ...mapActions('auth', ['logout']),
    disconnect() {
      this.logout()
    },
  },
}
</script>
<style lang="scss" scoped>
.nav {
  // background-attachment: fixed !important;
  // background: linear-gradient(90deg, #eff8ff 25%, #ffffff 100%);
  // background-position: center !important;
  // background-size: cover !important;
  height: 92px !important;
  width: 100% !important;
  padding-top: 40px;
}

.nav-logged {
  // background-attachment: fixed !important;
  // background: linear-gradient(90deg, #eff8ff 25%, #ffffff 100%);
  // background-position: center !important;
  // background-size: cover !important;
  height: 92px !important;
  width: 100% !important;
  padding-top: 40px;
}

$margin: 2rem;

.creator-area {
  float: right;
  margin-right: $margin;
}

.creator-area-mobile {
  display: none;
}

.logo-mobile {
  display: none;
}

@media screen and (max-width: 600px) {
  .creator-area-mobile {
    float: right;
    margin-right: $margin;
    display: block;
  }

  .creator-area {
    display: none;
  }

  .container {
    display: block;
    background-image: url('../../assets/logo.png');
    width: 50%;
    height: 50px;
    background-size: cover;
    background-position: 50% 50%;
  }

  .logo-mobile {
    display: block;
    width: 100%;
  }

  #main-logo-image {
    width: 100px;
    height: 100px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    margin: 0 auto;
  }
}

.logo-header {
  float: left;
  margin-left: $margin;
  height: '51px';
  width: '152px';
}
.logo-agency {
  float: left;
  margin-left: $margin;
  height: '51px';
}
</style>
