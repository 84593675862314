<template>
  <div>
    <v-menu offset-y>
      <template #activator="{ on, attrs, value }">
        <v-btn
          class="btn-dropdown"
          outlined
          rounded
          color="#0EA2B3"
          v-bind="attrs"
          v-on="on"
        >
          <avatar
            class="initals"
            :fullname="studentInfo.name"
            size="25"
            color="#0EA2B3"
          ></avatar>
          {{ studentInfo.name }}

          <v-icon
            v-if="value"
            class="chevron-icon fa-solid fa-chevron-up"
            color="#0EA2B3"
            size="10"
          ></v-icon>
          <v-icon
            v-else
            class="chevron-icon fa-solid fa-chevron-down"
            color="#0EA2B3"
            size="10"
          ></v-icon>
        </v-btn>
      </template>
      <v-list class="list-dropdown">
        <v-list-item v-for="(item, index) in items" :key="index">
          <v-list-item-title class="pointer" @click="goTo(item.route)">{{
            item.title
          }}</v-list-item-title>
        </v-list-item>
        <v-divider color="#0EA2B3"></v-divider>
        <v-list-item>
          <v-list-item-title class="pointer" @click="$emit('logout')"
            >Logout</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import Avatar from 'vue-avatar-component'
export default {
  name: 'UserDropdownComponent',
  components: {
    Avatar,
  },
  data: () => ({
    items:
      JSON.parse(localStorage.getItem('travelList')).length &&
      localStorage.getItem('travelList')
        ? [
            { title: 'Meu Perfil', route: '/meu-perfil' },
            { title: 'Meus Intercâmbios', route: '/meus-intercambios' },
          ]
        : [{ title: 'Meu Perfil', route: '/meu-perfil' }],
    studentInfo: JSON.parse(localStorage.getItem('studentInfo')),
  }),
  beforeMount() {
    let items =
      JSON.parse(localStorage.getItem('travelList')).length &&
      localStorage.getItem('travelList')
        ? [
            { title: 'Meu Perfil', route: '/meu-perfil' },
            { title: 'Meus Intercâmbios', route: '/meus-intercambios' },
          ]
        : []
    this.items = items
  },
  methods: {
    goTo(route) {
      this.$router.push(route)
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-dropdown {
  background-color: #fff;
  font-size: 14px;
  text-transform: capitalize;
}
.initals {
  margin-right: 10px;
}
.chevron-icon {
  margin-left: 10px;
}

.list-dropdown {
  width: 200px;
}
</style>
