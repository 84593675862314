import { ally } from '@/api'

const state = () => ({
  checkList: [],
  checklistFile: [],
})

const getters = {}

const actions = {
  async getCheckList({ commit }, payload) {
    ally
      .get(
        `/checklist?student_id=${payload.id}&&status=pending&page=${payload.page}`
      )
      .then(({ data }) => {
        const response = data
        commit('setCheckList', response)
      })
  },
  async getChecklistFile({ commit }, payload) {
    await ally
      .get(`/checklistfile?checklist_id=${payload}`)
      .then(({ data }) => {
        const response = data
        localStorage.setItem(`hasfile_${payload}`, JSON.stringify(response))
        commit('setChecklistFile', response)
      })
  },
}

const mutations = {
  setCheckList(state, value) {
    state.checkList = value
  },
  setChecklistFile(state, value) {
    state.checklistFile = value
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
