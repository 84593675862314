import { quote, ally } from '@/api'
import store from 'vuex'

const state = () => ({
  quoteInfo: [],
  coursesHash: [],
  firstPriceHash: [],
  accommodations: [],
  accommodationHash: [],
  fees: [],
  feePriceHash: [],
  quoteOffice: [],
  quoteStudent: [],
  quoteLegalGuardian: [],
  checkoutStep: 1,
  checkpoint: true,
})

const getters = {
  quoteInfo(state) {
    const quoteInfo = JSON.parse(localStorage.getItem('quoteInfo'))
    return state.quoteInfo && state.quoteInfo.length > 0
      ? state.quoteInfo
      : quoteInfo
  },
  quoteStudent(state) {
    const quoteStudent = JSON.parse(localStorage.getItem('quoteStudent'))
    return state.quoteStudent && state.quoteStudent.length > 0
      ? state.quoteStudent
      : quoteStudent
  },
  officeInfo(state) {
    const quoteOffice = JSON.parse(localStorage.getItem('quoteOffice'))
    return state.quoteOffice && state.quoteOffice.length > 0
      ? state.quoteOffice
      : quoteOffice
  },
  coursesInfo(state) {
    const coursesInfo = JSON.parse(localStorage.getItem('coursesInfo'))
    return state.coursesHash && state.coursesHash.length > 0
      ? state.coursesHash
      : coursesInfo
  },
  accommodations(state) {
    const accommodations = JSON.parse(localStorage.getItem('accommodations'))
    return state.accommodations && state.accommodations.length > 0
      ? state.accommodations
      : accommodations
  },
  accommodationInfo(state) {
    const accommodationInfo = JSON.parse(
      localStorage.getItem('accommodationInfo')
    )
    return state.accommodationHash && state.accommodationHash.length > 0
      ? state.accommodationHash
      : accommodationInfo
  },
  feeInfo(state) {
    const feeInfo = JSON.parse(localStorage.getItem('feeInfo'))
    return state.feePriceHash && state.feePriceHash.length > 0
      ? state.feePriceHash
      : feeInfo
  },
}

const actions = {
  getFirstPriceHash({ commit }, payload) {
    const url = '/firstpricehash/' + payload.id
    delete payload.id
    try {
      quote
        .get(url, {
          params: payload,
        })
        .then(({ data }) => {
          const response = data
          commit('setFirstPriceHash', response)
        })
    } catch (err) {
      console.log(err)
    }
  },
  getAccommodationHash({ commit }, payload) {
    const url = '/accommodationpricehash/' + payload.id
    delete payload.id
    try {
      quote
        .get(url, {
          params: payload,
        })
        .then(({ data }) => {
          const response = data
          commit('setAccommodationHash', response)
        })
    } catch (err) {
      console.log(err)
    }
  },
  getFeePriceHash({ commit }, payload) {
    const url = '/feepricehash/' + payload.id
    delete payload.id
    try {
      quote
        .get(url, {
          params: payload,
        })
        .then(({ data }) => {
          const response = data
          commit('setFeePriceHash', response)
        })
    } catch (err) {
      console.log(err)
    }
  },
  async updateCurrency({ commit, getters }, quote_id, hash) {
    const url =
      '/quotehash/' +
      quote_id +
      '?hash=' +
      getters.quoteInfo.hash +
      '&forceUpdateCurrency=true'
    try {
      await quote.get(url).then(({ data }) => {
        let quoteInfo = getters.quoteInfo
        quoteInfo.converted_value = data.converted_value
        quoteInfo.converted_value_without_sr = data.converted_value_without_sr
        commit('setQuoteInfo', quoteInfo)
      })
    } catch (err) {
      console.log(err)
    }
  },
  async saveCheckoutStep({ commit }, payload) {
    try {
      await ally
        .post('/checkoutstep', null, { params: payload })
        .then(({ data }) => {
          const response = data
          commit('setCheckoutStep', response.checkoutStep)
        })
    } catch (err) {
      console.log(err)
    }
  },
  async getCheckoutStep({ commit }, payload) {
    try {
      await ally
        .get('/checkoutstep', {
          params: payload,
        })
        .then(({ data }) => {
          const response = data
          commit('setCheckoutStep', response.checkoutStep)
        })
    } catch (err) {
      console.log(err)
    }
  },
}

const mutations = {
  setQuoteInfo(state, payload) {
    state.quoteInfo = payload
    localStorage.setItem('quoteInfo', JSON.stringify(payload))
    localStorage.setItem('quoteInfoHash', payload.hash)
    localStorage.setItem('campusTermId', payload.quote_campus_termId)
  },
  setQuoteHash(state, payload) {
    state.quoteHash = payload
  },
  setCoursesHash(state, payload) {
    // payload.srReject = []
    state.coursesHash = payload
    localStorage.setItem('coursesInfo', JSON.stringify(payload))
  },
  setFirstPriceHash(state, payload) {
    state.firstPriceHash.push(payload)
  },
  setAccommodations(state, payload) {
    state.accommodations = payload
    localStorage.setItem('accommodations', JSON.stringify(state.accommodations))
  },
  setAccommodationHash(state, payload) {
    state.accommodationHash.push(payload)
    localStorage.setItem(
      'accommodationInfo',
      JSON.stringify(state.accommodationHash)
    )
  },
  setFees(state, payload) {
    state.fees = payload
    localStorage.setItem('fees', JSON.stringify(payload))
  },
  setFeePriceHash(state, payload) {
    state.feePriceHash.push(payload)
    localStorage.setItem('feeInfo', JSON.stringify(state.feePriceHash))
  },
  setQuoteOffice(state, payload) {
    state.quoteOffice = payload
    localStorage.setItem('quoteOffice', JSON.stringify(payload))
  },
  setQuoteStudent(state, payload) {
    state.quoteStudent = payload
    localStorage.setItem('quoteStudent', JSON.stringify(payload))
  },
  setQuoteLegalGuardian(state, payload) {
    state.quoteLegalGuardian = payload
    localStorage.setItem('legalGuardian', JSON.stringify(payload))
  },
  setCheckoutStep(state, payload) {
    state.checkoutStep = payload
  },
  resetCheckpointStatus(state) {
    state.checkpoint = false
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
