import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'
import VueTheMask from 'vue-the-mask'
import VueVirtualScroller from 'vue-virtual-scroller'
import { firestorePlugin } from 'vuefire'
import { initializeApp } from 'firebase/app'
import './plugins/vuetify-money'
import './styles/ally-styles.scss'
import './styles/checkout.scss'
import VueSimpleAlert from 'vue-simple-alert'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIRBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIRBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIRBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIRBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIRBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIRBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIRBASE_MEASUREMENT_ID,
}

initializeApp(firebaseConfig)

Vue.use(VueTheMask)

Vue.component('VTextField', VTextField)

Vue.use(require('vue-dayjs'))
Vue.use(VueVirtualScroller)
Vue.use(require('vue-dayjs'))
Vue.use(firestorePlugin)

Vue.use(VueSimpleAlert)

Vue.use(VCurrencyField, {
  locale: 'pt-BR',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true,
})

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
