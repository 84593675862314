import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'
import store from '../store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth)
  // const isAuthenticated = store.getters['auth/isAuthenticated']
  const isAuthenticated =
    localStorage.getItem('token') && localStorage.getItem('travelList')

  if (requiresAuth && !isAuthenticated) {
    router.push('/login')
  } else if (requiresAuth && isAuthenticated) {
    next((vm) => {
      vm.$router.push(to.path)
    })
  } else if ((to.path === '/login' || to.path === '/') && isAuthenticated) {
    router.push('/espaco-estudante')
  } else {
    next()
  }
})

export default router
