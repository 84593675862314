<template>
  <v-app>
    <div class="fill-height hold-content safe-border">
      <v-app-bar
        v-if="showSandboxWarning"
        :elevation="2"
        height="20px"
        color="error"
        style="color: white; font-size: 15px; z-index: 10"
      >
        Atenção! Você está em um ambiente sandbox.
      </v-app-bar>
      <router-view name="header">
        <Header />
      </router-view>
      <router-view name="navbar">
        <Navbar />
      </router-view>
      <v-main>
        <router-view />
        <v-snackbar
          v-model="snackbar.show"
          :timeout="0"
          :color="snackbar.color"
          rounded="pill"
        >
          {{ snackbar.text }}
          <template #action="{ attrs }">
            <v-btn text v-bind="attrs" @click="closeSnackbar"> Fechar </v-btn>
          </template>
        </v-snackbar>
      </v-main>
      <router-view name="bottom">
        <Bottom />
      </router-view>
    </div>
  </v-app>
</template>

<script>
import Header from './components/shared/Header.vue'
import Navbar from './components/dashboard/Navbar.vue'
import Bottom from '@/components/shared/Bottom.vue'

export default {
  name: 'App',
  components: {
    Header,
    Navbar,
    Bottom,
  },
  computed: {
    snackbar() {
      const sb = { ...this.$store.getters['snackbar/snackbar'] }
      return sb
    },
    showSandboxWarning() {
      return process.env.VUE_APP_ENVIRONMENT_TYPE === 'staging'
    },
  },
  methods: {
    closeSnackbar() {
      this.$store.commit('snackbar/closeSnackbar', false)
    },
  },
}
</script>
<style lang="scss" scoped>
.hold-content {
  background-attachment: fixed !important;
  background-color: white;
}
</style>
