import { ally } from '@/api'
import store from '@/store'

const state = () => ({
  student: {
    name: '',
    email: '',
    birthdate: '',
    cpf: '',
    hasResponsible: false,
    zipcode: '',
    address: '',
    number: '',
    complement: '',
    city: '',
    state: '',
    country_code: '',
    neighborhood: '',
    id: null,
    areaCode: '',
    phone: '',
  },
  studentData: {
    name: '',
    email: '',
    birthdate: '',
    cpf: '',
    zipcode: '',
    address: '',
    city: '',
    state: '',
    number: '',
  },
  contractSigned: false,
})

const getters = {
  student: (state) => state.student,
  studentInfo(state) {
    const studentInfo = JSON.parse(localStorage.getItem('studentInfo'))
    return state.student.id ? state.student : studentInfo
  },
}

const actions = {
  async updateStudentData({ commit }, payload) {
    ally
      .put(`/student/${payload.id}`, payload)
      .then((res) => {
        commit('setStudent', payload)
      })
      .catch((err) => {
        store.commit('snackbar/showSnackbar', {
          text: 'Ocorreu um erro na alteração',
          color: 'error',
        })
      })
      .finally(() => {
        store.commit('snackbar/showSnackbar', {
          text: 'Os dados foram alterados com sucesso',
          color: 'success',
        })
      })
  },
  async deleteStudentData({ commit }, payload) {
    ally
      .delete(`/student/${payload.id}`)
      .then((res) => {
        store.commit('snackbar/showSnackbar', {
          text: 'Sua solicitação foi enviada para nossa equipe. Você receberá um e-mail de confirmação.',
          color: 'success',
        })
        store.commit('auth/logout')
      })
      .catch((err) => {
        store.commit('snackbar/showSnackbar', {
          text: 'Ocorreu um erro com a sua solicitação',
          color: 'error',
        })
      })
  },
}

const mutations = {
  setStudent(state, value) {
    const quoteInfo = store.getters['quote/quoteInfo']
    state.student.name = value.name ? value.name : ''
    state.student.surname = value.surname ? value.surname : ''
    state.student.email = value.email ? value.email : ''
    state.student.birthdate = value.birthdate ? value.birthdate : ''
    state.student.cpf = value.cpf ? value.cpf : ''
    state.student.hasResponsible = value.hasResponsible
      ? value.hasResponsible
      : false
    state.student.zipcode = value.zipcode ? value.zipcode : ''
    state.student.address = value.address ? value.address : ''
    state.student.number = value.number ? value.number : ''
    state.student.complement = value.complement ? value.complement : ''
    state.student.city = value.city ? value.city : ''
    state.student.state = value.state ? value.state : ''
    state.student.country_code = 'BRA'
    state.student.neighborhood = value.neighborhood ? value.neighborhood : ''
    state.student.id = value.id ? value.id : ''
    state.student.areaCode = value.areaCode ? value.areaCode : ''
    state.student.phone = value.phone ? value.phone : ''
    state.student.phone1 = value.phone1 ? value.phone1 : ''
    state.student.phone2 = value.phone2 ? value.phone2 : ''
    state.student.gender = value.gender ? value.gender : ''
    state.student.maritalStatus = value.maritalStatus ? value.maritalStatus : ''
    state.student.job = value.job ? value.job : ''
    state.student.highSchool = value.highSchool ? value.highSchool : ''
    state.student.highSchoolCompletion = value.highSchoolCompletion
      ? value.highSchoolCompletion
      : ''
    state.student.highSchoolGPA = value.highSchoolGPA ? value.highSchoolGPA : ''
    state.student.higherEducation = value.higherEducation
      ? value.higherEducation
      : ''
    state.student.higherEducationCompletion = value.higherEducationCompletion
      ? value.higherEducationCompletion
      : ''
    state.student.higherEducationGPA = value.higherEducationGPA
      ? value.higherEducationGPA
      : ''
    state.student.idiom = value.idiom ? value.idiom : ''
    state.student.idiomLevel = value.idiomLevel ? value.idiomLevel : ''
    state.student.higherEducationProgress = value.higherEducationProgress
      ? value.higherEducationProgress
      : ''

    state.student.registerNumber = value.registerNumber
      ? value.registerNumber
      : ''
    state.student.registerNumber2 = value.registerNumber2
      ? value.registerNumber2
      : ''
    state.student.passport = value.passport ? value.passport : ''
    state.student.passportValidity = value.passportValidity
      ? value.passportValidity
      : ''
    state.student.birthDate = value.birthDate ? value.birthDate : ''
    state.student.startforecast = value.startforecast ? value.startforecast : ''
    state.student.interestcity = value.interestcity ? value.interestcity : ''
    state.student.interestcountry = value.interestcountry
      ? value.interestcountry
      : ''

    localStorage.setItem('studentInfo', JSON.stringify(value))
    if (quoteInfo) {
      localStorage.setItem('quoteSessionToRestore', quoteInfo.id)
    }
    store.commit('payment/resetPixPayment')
  },
  setInitialInfoStudent(state, value) {
    state.studentData.name = value.student_name
    state.studentData.email = value.student_email
    state.studentData.cpf = value.student_cpf
    state.studentData.address = value.student_address
    state.studentData.city = value.student_city
    state.studentData.state = value.student_state
    state.studentData.neighborhood = value.student_neighborhood
    state.studentData.complement = value.student_complement
    state.studentData.number = value.student_number
    state.studentData.zipcode = value.student_cep
    state.studentData.birthdate = value.student_birthdate
  },
  changeStudentEmail(state, value) {
    state.studentData.email = value
  },
  setContractSigned(state) {
    state.contractSigned = true
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
