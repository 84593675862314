<template>
  <div>
    <v-app-bar
      color="white"
      dense
      flat
      dark
      elevation="2"
      class="navbar-checkout pa-7"
    >
      <img v-if="quoteOffice.has_logo" class="img-office" :src="showImage" />

      <v-spacer />
      <div class="creator-area">
        <div style="float: left">
          <div class="info-div">
            <p class="main-text">
              {{ quoteOffice.creator_name }}
            </p>
            <p class="mute-text">{{ quoteOffice.creator_email }}</p>
            <p class="mute-text">{{ quoteOffice.office_name }}</p>
          </div>
        </div>
        <div class="user-box">
          <div
            class="creator-img-default"
            :style="'background-image: url(https://quote.sellead.com/images/user_placeholder.png)'"
          >
            <div
              class="creator-img-default"
              :style="'background-image: url(' + showUserImage() + ')'"
            ></div>
          </div>
          <a
            v-if="quoteOffice.creator_phone"
            :href="
              'https://web.whatsapp.com/send?phone=' + quoteOffice.creator_phone
            "
            target="_blank"
          >
            <div class="wp-box pointer zoom"></div>
          </a>
        </div>
      </div>

      <template #extension>
        <v-row no-gutters align="center" justify="space-between">
          <!-- BREADCRUMB ROW -->
          <breadcrumb />
        </v-row>
      </template>
    </v-app-bar>
  </div>
</template>

<script>
import Breadcrumb from '@/components/shared/Breadcrumb'
import { mapState } from 'vuex'

export default {
  name: 'HeaderComponent',
  components: {
    Breadcrumb,
  },
  computed: {
    ...mapState('quote', ['quoteInfo', 'quoteOffice']),
    showImage() {
      return `${process.env.VUE_APP_QUOTE_API}/uploads/account_${this.quoteInfo.account_id}/office_${this.quoteOffice.office_id}_logo.jpg`
    },
  },
  methods: {
    showUserImage() {
      var img = new Image()
      img.src = `${process.env.VUE_APP_QUOTE_API}/uploads/account_${this.quoteInfo.account_id}/user/avatar_${this.quoteInfo.creator_id}.jpg`
      return img.src
    },
  },
}
</script>

<style scoped>
.navbar-checkout {
  height: '60px';
}
.img-office {
  height: 4.5rem;
  width: auto;
}
.creator-img-default {
  width: 60px;
  height: 60px;
  border-radius: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  float: right;
  margin-left: 20px;
}
.user-box {
  float: right;
}
.info-div {
  text-align: right;
  line-height: 0.4;
  padding-top: 4px;
  color: rgba(0, 0, 0, 0.87);
}

.main-text {
  color: rgb(14, 162, 179);
  font-weight: bold;
}
.wp-box {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-image: url('../../assets/Allyhub/wp_icon.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  margin-left: 60px;
  margin-top: 35px;
}
.creator-area {
  color: black;
}
.mute-text {
  color: #9e9e9e;
}
@media screen and (max-width: 600px) {
  .navbar-checkout {
    height: '30px';
  }
  .img-office {
    height: 3rem;
    width: auto;
  }
  .creator-img-default {
    width: 60px;
    height: 60px;
    border-radius: 100px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    float: right;
    margin-left: 20px;
  }
  .user-box {
    float: right;
  }
  .wp-box {
    width: 30px;
    height: 30px;
    border-radius: 100px;
    background-image: url('../../assets/Allyhub/wp_icon.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    margin-left: 60px;
    margin-top: 35px;
    z-index: 1;
  }
  .info-div {
    display: none;
  }
}
</style>
